'use strict';

angular.module('cpformplastApp')
  .controller('NavbarController', function ($scope, Auth, $state, Modal, Util) {

    $scope.isLoggedIn = Auth.isLoggedIn;

    $scope.logout = Modal.confirm.logout(res => {
      $state.go('logout');
		});

    $scope.currentDateString = Util.getDateString(new Date());

});
